import { render, staticRenderFns } from "./Showcase.vue?vue&type=template&id=541bd400&"
import script from "./Showcase.vue?vue&type=script&lang=js&"
export * from "./Showcase.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoPlayer: require('/Users/eralpsoftwareweb/Desktop/Active Projects/Heocademy/Gökçe Özel/gokce-ozel-nuxt/components/VideoPlayer/Player.vue').default})
